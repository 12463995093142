import React from "react";
import FeatureCard from "../../components/card/featureCard";
import Section from "../../components/section";
import bgD3 from "../../images/background-deco-3.svg";

import {
  MdMobileFriendly,
  MdTipsAndUpdates,
  MdWifiOff,
  MdMore,
} from "react-icons/md";

const Features = () => {
  return (
    <Section backgrounds={{ lg: [bgD3], sm: [bgD3] }} id="features" autoHeight>
      <div className="flex flex-col justify-center items-center w-full">
        <p className="text-white text-6xl mb-4 text-center">應用特色</p>
        <p className="text-white text-xl mb-4 text-center">
          為何要選擇此應用？
        </p>
        <div className="bg-white rounded-md shadow-md flex flex-wrap p-8 justify-around self-stretch ">
          <FeatureCard
            icon={<MdMobileFriendly />}
            title="簡易使用"
            content="應用界面保持簡潔，使用流程清晰，讓你能專心練習， 另外應用也有初次使用的介紹，方便新用家熟習軟件。"
          />
          <FeatureCard
            icon={<MdTipsAndUpdates />}
            title="提示系統"
            content="在練習時可使用提示來取得下一個字碼，免去因一個字碼而阻礙整個練習的煩惱。"
          />
          <FeatureCard
            icon={<MdWifiOff />}
            title="無需聯網"
            content="應用內建文字資料庫，無需連接網絡下載練習，隨時隨地可以開始練習。"
          />
          <FeatureCard
            icon={<MdMore />}
            title="折碼詳情"
            content="在練習常用字時，透過拆碼詳情來查看各字碼對應的字型，有助熟識辨認字型及配對其字碼。"
          />
        </div>
      </div>
    </Section>
  );
};

export default Features;
