import React from "react";

const FeatureCard = ({ icon, title, content }) => {
  return (
    <div className="w-48 flex-col mx-4">
      <div className="text-5xl mb-4">{icon}</div>
      <p className="mb-4">{title}</p>
      <p className="mb-4">{content}</p>
    </div>
  );
};

export default FeatureCard;
